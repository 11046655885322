import * as React from 'react'

export const ActiveCampaignIcon = (props: any) =>
<svg
  className={`system-icon r-active-campaign ${props.className}`}
  width={props.width || 288}
  height={props.height || 288}
  viewBox="0 0 288 288"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <g>
    <path d="M35.8 0H251.8C271.7 0 287.8 16.1 287.8 36V252C287.8 271.9 271.7 288 251.8 288H35.8C15.9 288 -0.200012 271.9 -0.200012 252V36C-0.200012 16.1 15.9 0 35.8 0Z" fill="#356AE6"/>
    <path d="M146.6 150.7C149.2 150.7 151.8 149.7 154.6 147.6C157.9 145.3 160.8 143.4 160.8 143.4L161.8 142.7L160.8 142C160.4 141.7 116.9 111.5 112.4 108.6C110.3 107.1 107.9 106.8 106 107.7C104.2 108.6 103.2 110.5 103.2 112.8V123.1L103.6 123.3C103.9 123.5 133.8 144.3 139.6 148.2C142 149.9 144.3 150.7 146.6 150.7Z" fill="white"/>
    <path d="M204.9 132.6C202.3 130.7 109 65.6 105 62.8L103.7 61.9V77.9C103.7 83 106.4 84.9 109.7 87.3C109.7 87.3 181.3 137.2 190.2 143.3C181.3 149.5 113.9 196.2 109.6 198.9C104.5 202.3 104 204.5 104 209.1V226.3C104 226.3 202.7 155.7 204.8 154.1C209.3 150.7 210.3 146.7 210.4 143.8V142C210.5 138.4 208.6 135.2 204.9 132.6Z" fill="white"/>
  </g>
</svg>
