import * as React from 'react'
import './account-and-billing.css'

export const AccountAndBilling = (props: any) =>
<svg xmlns="http://www.w3.org/2000/svg" id="Group_6289" className="panel-icon" data-name="Group 6289" viewBox="0 0 77.627 58.005" width={props.svgwidth} height={props.svgheight}>
    <g id="Group_6286" data-name="Group 6286">
        <g id="Group_6282" data-name="Group 6282">
            <g id="Group_6276" data-name="Group 6276">
                <g id="Group_6266" data-name="Group 6266">
                    <path id="Path_12224" d="M539.919 630.254h-13.756a.786.786 0 0 0-.786.786v9.825a.786.786 0 0 0 .786.786h7.607a2.356 2.356 0 1 0 0-1.572h-6.821v-8.253h12.97a5.115 5.115 0 0 1 5.109 5.109v33.407a5.115 5.115 0 0 1-5.109 5.109h-47.163a5.115 5.115 0 0 1-5.109-5.109v-33.407a5.115 5.115 0 0 1 5.109-5.109h21.362a2.356 2.356 0 1 0 0-1.572h-21.362a6.689 6.689 0 0 0-6.681 6.681v33.407a6.689 6.689 0 0 0 6.681 6.682h47.163a6.689 6.689 0 0 0 6.681-6.682v-33.407a6.689 6.689 0 0 0-6.681-6.681z" className="account-and-billing-icon-1" data-name="Path 12224" transform="translate(-486.075 -628.684)"/>
                    <path id="Path_12225" d="M666.86 738.246h15.721a.786.786 0 1 0 0-1.572H666.86a.786.786 0 1 0 0 1.572z" className="account-and-billing-icon-1" data-name="Path 12225" transform="translate(-630.702 -715.453)"/>
                    <path id="Path_12226" d="M698.65 776.674h-11.79a.786.786 0 0 0 0 1.572h11.79a.786.786 0 1 0 0-1.572z" className="account-and-billing-icon-1" data-name="Path 12226" transform="translate(-646.772 -747.592)"/>
                    <path id="Path_12227" d="M698.65 816.674h-11.79a.786.786 0 1 0 0 1.572h11.79a.786.786 0 1 0 0-1.572z" className="account-and-billing-icon-1" data-name="Path 12227" transform="translate(-646.772 -779.732)"/>
                </g>
                <g id="Group_6267" data-name="Group 6267" transform="translate(5.485 10.947)">
                    <path id="Path_12228" d="M548.3 773.346a8.9 8.9 0 0 0-6.381 5.992 12.817 12.817 0 0 0 1.376 1.063c-.107-.1-.217-.2-.322-.306a10.593 10.593 0 0 1 5.327-6.749z" className="account-and-billing-icon-2" data-name="Path 12228" transform="translate(-536.43 -755.865)"/>
                    <path id="Path_12229" d="M563.227 715.03a5.191 5.191 0 0 1 3.948-4.705c-.043 0-.083-.013-.126-.013a4.451 4.451 0 0 0-.766 8.837 4.646 4.646 0 0 1-3.056-4.119z" className="account-and-billing-icon-2" data-name="Path 12229" transform="translate(-553.04 -705.218)"/>
                    <path id="Path_12230" d="M533.2 690.871a12.749 12.749 0 0 0-12.734 12.734 12.59 12.59 0 0 0 3.225 8.455 10.181 10.181 0 0 1 19.019 0 12.59 12.59 0 0 0 3.225-8.455 12.749 12.749 0 0 0-12.735-12.734zm0 14.008a5.731 5.731 0 1 1 5.731-5.731 5.737 5.737 0 0 1-5.731 5.731z" className="account-and-billing-icon-2" data-name="Path 12230" transform="translate(-519.196 -689.598)"/>
                    <path id="Path_12231" d="M574.1 714.821a4.455 4.455 0 0 0-4.331-4.444 5.191 5.191 0 0 0-3.947 4.706 4.646 4.646 0 0 0 3.055 4.118 4.4 4.4 0 0 0 5.224-4.38z" className="account-and-billing-icon-3" data-name="Path 12231" transform="translate(-555.64 -705.27)"/>
                    <path id="Path_12232" d="M561.841 703.832a5.731 5.731 0 1 0 5.731 5.731 5.737 5.737 0 0 0-5.731-5.731zm-4.457 5.731a4.462 4.462 0 0 1 4.457-4.457c.043 0 .083.011.126.013a4.445 4.445 0 1 1-4.583 4.444z" className="account-and-billing-icon-1" data-name="Path 12232" transform="translate(-547.834 -700.012)"/>
                    <path id="Path_12233" d="M554.747 771.875a8.854 8.854 0 0 0-2.139.289 10.593 10.593 0 0 0-5.327 6.749c.106.1.215.2.322.306a12.7 12.7 0 0 0 15.663-1.063 8.92 8.92 0 0 0-8.519-6.281z" className="account-and-billing-icon-3" data-name="Path 12233" transform="translate(-540.739 -754.683)"/>
                    <path id="Path_12234" d="M542 698.4a14.008 14.008 0 0 0-28.016 0 13.858 13.858 0 0 0 4.063 9.845l-.006.022.271.259a13.961 13.961 0 0 0 17.947 1.172 14.1 14.1 0 0 0 1.41-1.172l.268-.255-.005-.021A13.861 13.861 0 0 0 542 698.4zm-14 12.733a13.014 13.014 0 0 1-8.519-3.27 8.918 8.918 0 0 1 17.038 0 12.679 12.679 0 0 1-8.519 3.27zm9.51-4.28a10.182 10.182 0 0 0-19.02 0 12.592 12.592 0 0 1-3.225-8.455 12.735 12.735 0 0 1 25.469 0 12.593 12.593 0 0 1-3.227 8.456z" className="account-and-billing-icon-1" data-name="Path 12234" transform="translate(-513.989 -684.391)"/>
                </g>
            </g>
            <g id="Group_6281" data-name="Group 6281" transform="rotate(10.02 -64.34 237.237)">
                <path id="Path_344-2" d="M19.047.547c.834 0 1.685-.51 2.491-.407.824.107 1.506.836 2.292 1.042a18.068 18.068 0 0 1 2.241.871c.764.31 1.733.148 2.442.549a18.933 18.933 0 0 1 1.927 1.514 18.716 18.716 0 0 1 1.654 1.762c.582.568 1.6.758 2.1 1.4a18.774 18.774 0 0 1 1.285 2.082 19.149 19.149 0 0 1 .686 2.345 17.613 17.613 0 0 1 .954 2.2 17.874 17.874 0 0 1 .3 4.737c0 .814.577 1.64.474 2.427a18.786 18.786 0 0 1-.315 2.43 18.837 18.837 0 0 1-1.047 2.226 18.463 18.463 0 0 1-1.047 2.2 19.436 19.436 0 0 1-1.105 2.225c-.5.636-1.628.759-2.209 1.327a19.016 19.016 0 0 1-1.652 1.787 18.921 18.921 0 0 1-2.144 1.179 18.438 18.438 0 0 1-2.3.779 18.422 18.422 0 0 1-2.174 1.109 18.96 18.96 0 0 1-2.4.493 19.949 19.949 0 0 1-2.447-.153c-.834 0-1.688.6-2.491.493a19.544 19.544 0 0 1-2.449-.483c-.8-.209-1.416-.935-2.172-1.242a18.92 18.92 0 0 1-2.258-.92c-.715-.4-1.7-.447-2.35-.936s-.759-1.6-1.339-2.166-1.77-.628-2.273-1.267a19.3 19.3 0 0 1-1.344-2.09c-.41-.693-.2-1.7-.519-2.451S.823 24.259.609 23.476a18.483 18.483 0 0 1-.6-2.382c-.105-.786.462-1.636.462-2.452a18.465 18.465 0 0 1-.063-2.4 17.817 17.817 0 0 1 .794-2.277 17.2 17.2 0 0 1 .863-2.2 18.275 18.275 0 0 1 .744-2.29A18.488 18.488 0 0 1 4.338 7.6c.5-.636.665-1.563 1.245-2.132a19.964 19.964 0 0 1 2.109-1.307A18.48 18.48 0 0 1 9.6 2.634a18.938 18.938 0 0 1 2.176-1.168A19.58 19.58 0 0 1 14.2.921a19.491 19.491 0 0 1 2.346-.91c.8-.104 1.667.536 2.501.536z" className="account-and-billing-icon-4" data-name="Path 344-2"/>
                <path id="Path_12265" d="M13.509 12.909a8.379 8.379 0 0 0-2.115-1.173 16.517 16.517 0 0 0-2.342-.713 14.721 14.721 0 0 1-2.913-.938 1.588 1.588 0 0 1-.971-1.478 1.429 1.429 0 0 1 .266-.886 2.014 2.014 0 0 1 .713-.612 4.048 4.048 0 0 1 .97-.348 5.116 5.116 0 0 1 1-.129 4.811 4.811 0 0 1 1.309.149 6.145 6.145 0 0 1 1.14.42 7.156 7.156 0 0 1 .938.551q.415.292.7.538l2.66-3.07a12.254 12.254 0 0 0-2.025-1.442A8.174 8.174 0 0 0 9.532 2.8L9.481 0l-3.02.054.051 2.8a7.235 7.235 0 0 0-4.26 1.952A5.284 5.284 0 0 0 .766 8.783 5.071 5.071 0 0 0 2.313 12.5a9.9 9.9 0 0 0 4.478 2.217q1.2.323 2.041.592a6.291 6.291 0 0 1 1.368.591 2.21 2.21 0 0 1 .752.694 1.659 1.659 0 0 1 .236.846 1.745 1.745 0 0 1-.234.963 1.908 1.908 0 0 1-.65.642 2.866 2.866 0 0 1-.906.347 5.492 5.492 0 0 1-1.005.113 6.7 6.7 0 0 1-3.239-.713 9.062 9.062 0 0 1-2.376-1.7L0 20.284a10.642 10.642 0 0 0 6.887 3.147l.053 2.927 3.02-.056-.06-3.051a8.665 8.665 0 0 0 2.284-.719 6.793 6.793 0 0 0 1.834-1.26 5.549 5.549 0 0 0 1.212-1.755 5.244 5.244 0 0 0 .415-2.225 5.535 5.535 0 0 0-.614-2.6 5.693 5.693 0 0 0-1.522-1.783z" className="account-and-billing-icon-5" data-name="Path 12265" transform="rotate(-9 48.341 -54.93)"/>
            </g>
        </g>
    </g>
</svg>
