import { GridColumn as Column } from '@progress/kendo-react-grid'
import { isNil } from 'lodash'
import * as React from 'react'

export const TABLET_DISPLAY_WIDTH: number = 700
export const LEFT_PANEL_DEFAULT_WIDTH: number = 500

export interface SizableColumnState {
  panelWidth: number,
}

export interface SizableColumn {
  cell?: any,
  editable?: boolean,
  field?: string,
  headerCell?: any,
  minWidth?: number,
  minDisplayWidth?: number,
  sortable?: boolean
  title: string,
  width?: number,
  requiredRoles?: string[],
  hidden?: boolean,
}

export class SizableColumns {

  private columns: SizableColumn[]

  constructor(columns: SizableColumn[]) {
    this.columns = columns
  }

  public buildColumns = (panelWidth: number, currentRole: string = '') => {
    const requiredWidth: number = this.requiredWidth(panelWidth, currentRole)

    return this.displayedColumns(currentRole).map((c: SizableColumn, i: number) => {
      return <Column field={c.field} title={c.title} key={i + panelWidth}
                     cell={c.cell} headerCell={c.headerCell}
                     width={this.columnWidth(c, panelWidth, requiredWidth)}
                     editable={false} sortable={c.sortable}/>
    })
  }

  private displayedColumns = (currentRole: string): SizableColumn[] => {
    if ( !isNil(currentRole) ) {
      return this.columns.filter((c: SizableColumn) => !c.hidden &&
        (!c.requiredRoles || c.requiredRoles.includes(currentRole)))
    } else {
      return this.columns.filter((c: SizableColumn) => !c.hidden)
    }
  }

  private requiredWidth = (panelWidth: number, currentRole: string): number => {
    return this.displayedColumns(currentRole).reduce((width: number, c: SizableColumn) => {
      return width + (this.columnVisible(c, panelWidth) ? c.width || 0 : 0)
    }, 0)
  }

  private columnWidth = (c: SizableColumn, panelWidth: number, requiredWidth: number): number => {
    if ( !this.columnVisible(c, panelWidth) ) {
      return 0
    } else if (c.width) {
      // Fixed width - display it
      return c.width
    } else {
      // Variable width - display the max of its minimum or available
      return Math.max(c.minWidth, panelWidth - requiredWidth)
    }
  }

  private columnVisible = (c: SizableColumn, panelWidth: number): boolean => {
    return c.minDisplayWidth ?
      (panelWidth > 0 && c.minDisplayWidth < panelWidth) : true
  }
}
