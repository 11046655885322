import * as React from 'react'
import './articles.css'

export const Articles = (props: any) =>
<svg xmlns="http://www.w3.org/2000/svg" width={props.svgwidth} height={props.svgheight} className="panel-icon" viewBox="0 0 30.658 32.481">
    <g id="Group_6242" data-name="Group 6242" transform="translate(-42.276 -315.437)">
        <g id="Group_5500" data-name="Group 5500" transform="translate(8091 5070)">
            <g id="Group_5499" data-name="Group 5499" transform="translate(38 46.8)">
                <path id="Path_403-2" d="M.516 0C.319 0 .03.111.03.331c0 .907.059.907.059 1.814S0 3.053 0 3.96s.234.908.234 1.816-.149.91-.149 1.818S.05 8.5.052 9.412c.006.223.278.386.474.38s.258-.167.263-.38C.79 8.5.907 8.5.907 7.6s-.06-.91-.06-1.818.033-.908.033-1.816.1-.91.1-1.818S.915 1.239.915.33A.355.355 0 0 0 .535 0z" className="articles-1" data-name="Path 403-2" transform="rotate(47 1487.115 -11674.06)"/>
                <path id="Path_404-2" d="M.533 0A.652.652 0 0 0 0 .451c-.009.9.173.9.173 1.805s.061.9.061 1.8.153.9.153 1.8-.016.9-.016 1.8-.134.9-.134 1.8-.179.9-.179 1.805-.058.921-.04 1.823a.643.643 0 0 0 .6.426c.147-.032.147-.266.174-.435.012-.9.15-.9.15-1.8s-.127-.9-.127-1.8.068-.9.068-1.8S.8 6.768.8 5.867s.262-.9.262-1.8S.983 3.16.983 2.258s.128-.906.12-1.808A.7.7 0 0 0 .533 0z" className="articles-1" data-name="Path 404-2" transform="rotate(47 1492.781 -11665.387)"/>
                <path id="Path_405-2" d="M.393 0C.2 0 0 .264 0 .486c0 .749.082.749.082 1.5s.02.75.02 1.5-.012.75-.01 1.5c.007.222.113.344.31.337a.369.369 0 0 0 .385-.337c0-.749-.02-.749-.02-1.5s-.087-.75-.087-1.5.109-.75.109-1.5C.788.264.611.009.414.009.385 0 .393 0 .393 0z" className="articles-1" data-name="Path 405-2" transform="rotate(47 1484.72 -11662.467)"/>
                <path id="Path_406-2" d="M.39 0A.431.431 0 0 0 0 .425c0 .594.054.594.054 1.188a.374.374 0 0 0 .335.411.391.391 0 0 0 .356-.411c0-.594.032-.594.032-1.188A.43.43 0 0 0 .39 0z" className="articles-1" data-name="Path 406-2" transform="rotate(47 1477.002 -11659.587)"/>
            </g>
            <g id="Group_723" data-name="Group 723" transform="translate(-8414.676 -5006.077)">
                <g id="Group_723-2" data-name="Group 723-2">
                    <path id="Rectangle_123" d="M367.444 271.991c.561-1.747 1.665-.933 3.358-1.644s1.954-.089 3.648-.8 1.46-1.262 3.151-1.973 1.831-.382 3.522-1.093 1.793-.476 3.488-1.188 1.425-2.316 3.069-1.493c1.914.957.885 1.7 1.713 3.674s1 1.9 1.828 3.878 2.713 2.09 2.057 4.129c-.562 1.747-2.166.548-3.858 1.258s-1.576.988-3.271 1.7-1.878.267-3.569.977-1.745.588-3.438 1.3-1.432 1.337-3.127 2.048-1.8 1.751-3.439.929c-1.915-.957-1.013-1.761-1.841-3.735a13.644 13.644 0 0 0-1.862-3.783c-1.302-1.893-2.089-2.146-1.429-4.184z" className="articles-2" data-name="Rectangle 123"/>
                    <path id="Rectangle_124" d="M370.39 272.975c.545-1.52 1.671-.44 3.159-1.069s1.454-2.063 2.92-1.389c1.6.736.642 1.642 1.321 3.265s1.884 1.533 1.289 3.19c-.545 1.519-1.4.613-2.886 1.237s-1.427 1.845-2.893 1.171c-1.6-.736-.437-1.657-1.452-3.1-1.107-1.563-2.048-1.651-1.458-3.305z" className="articles-3" data-name="Rectangle 124"/>
                    <path id="Path_401" d="M381.539 268.263c1.793-.755 1.82-.689 3.614-1.442a.652.652 0 0 0 .27-.877l.133-.056a.751.751 0 0 0-.936-.338l.011.026c-1.793.755-1.828.667-3.622 1.42a.806.806 0 0 0-.389.963l.035-.016a.693.693 0 0 0 .893.336l.026-.023a.2.2 0 0 0-.035.007z" className="articles-4" data-name="Path 401"/>
                    <path id="Path_402" d="M385.573 267.822c-1.643.692-1.615.759-3.258 1.447s-1.635.712-3.278 1.4a.8.8 0 0 0-.429.943l.179-.077-.1.045a.644.644 0 0 0 .861.3l-.041-.1c1.644-.692 1.693-.571 3.337-1.258s1.679-.608 3.322-1.3a.79.79 0 0 0 .389-.949l-.057.023a.729.729 0 0 0-.864-.449c-.077 0-.061-.025-.061-.025z" className="articles-4" data-name="Path 402"/>
                    <path id="Path_403" d="M386.647 270.387c-1.644.692-1.715.521-3.358 1.21s-1.553.906-3.2 1.6a.653.653 0 0 0-.448.8l.139-.059-.158.069a.716.716 0 0 0 .927.287l-.009-.024.031.076c1.644-.692 1.6-.8 3.242-1.487s1.616-.755 3.26-1.447c.33-.139.418-.324.28-.654l.282-.053-.063-.039c-.138-.33-.549-.331-.881-.193-.01.006-.044-.086-.044-.086z" className="articles-4" data-name="Path 403"/>
                    <path id="Path_404" d="M387.681 272.844l-3.286 1.384c-1.644.692-1.641.7-3.285 1.384-.33.138-.6.44-.458.769l-.07.029a.891.891 0 0 0 1.028.4l-.07-.172.034.084c1.644-.692 1.668-.629 3.312-1.321s1.658-.656 3.3-1.346a.728.728 0 0 0 .257-.943l-.081.043-.007-.006c-.139-.33-.465-.632-.8-.494-.034.033.127.189.126.189z" className="articles-4" data-name="Path 404"/>
                    <path id="Path_399" d="M385.791 262.32l-19.517 8.193a.522.522 0 0 0-.281.684L371 283.12a.522.522 0 0 0 .683.28l19.519-8.2a.522.522 0 0 0 .279-.683l-5-11.924a.523.523 0 0 0-.688-.272zm4.524 12.13l-18.555 7.789-4.6-10.963 18.555-7.789z" className="articles-5" data-name="Path 399"/>
                    <path id="Path_400" d="M375.708 269.288l-5.952 2.5a1.044 1.044 0 0 0-.559 1.368l2.726 6.492a1.044 1.044 0 0 0 1.367.561l5.952-2.5a1.045 1.045 0 0 0 .559-1.368l-2.726-6.492a1.047 1.047 0 0 0-1.367-.561zm3.131 7.457l-5.952 2.5-2.726-6.492 5.952-2.5z" className="articles-5" data-name="Path 400"/>
                </g>
            </g>
        </g>
    </g>
</svg>
