import * as React from 'react'
import './changeplanicon.css'

export const Changeplanicon = (props: any) =>
<div className="changeplanicon">
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28.024 28.542">
  <g id="Group_6572" data-name="Group 6572" transform="translate(-1697 -462)">
    <g id="Group_6571" data-name="Group 6571" transform="translate(1293.551 166.651)">
      <path id="Path_21915" fill="#f1faf9" d="M431.114 309.659a14.284 14.284 0 00-.221-3.634 13.861 13.861 0 00-.707-1.688 14.829 14.829 0 00-.505-1.8 14.433 14.433 0 00-.953-1.6c-.368-.49-1.12-.635-1.549-1.071a13.889 13.889 0 00-1.223-1.354 14.281 14.281 0 00-1.424-1.161c-.525-.307-1.241-.184-1.805-.422a13.208 13.208 0 00-1.658-.668c-.584-.158-1.1-.72-1.694-.8a1.308 1.308 0 00-.165-.011c-.546 0-1.115.323-1.676.323s-1.168-.424-1.727-.424a1.065 1.065 0 00-.126.008 14.226 14.226 0 00-1.735.7 13.724 13.724 0 00-1.788.418 9.845 9.845 0 00-3.021 2.074 14.572 14.572 0 00-1.56 1c-.43.436-.551 1.144-.92 1.635a13.819 13.819 0 00-1.127 1.438 14.257 14.257 0 00-.55 1.758 13.615 13.615 0 00-.638 1.691 13.957 13.957 0 00-.587 1.748 14.534 14.534 0 00.046 1.843c0 .625-.42 1.276-.342 1.881a14.368 14.368 0 00.441 1.829c.158.6.688 1.069.923 1.638s.081 1.35.383 1.881a14.863 14.863 0 00.994 1.6c.368.491 1.25.537 1.68.973s.508 1.288.989 1.663 1.21.409 1.738.719a13.508 13.508 0 001.669.707c.559.234 1.013.791 1.605.952a14.16 14.16 0 001.81.371 1.126 1.126 0 00.142.009c.554 0 1.134-.388 1.7-.388a14.057 14.057 0 001.81.118 13.208 13.208 0 001.772-.379 13.37 13.37 0 001.609-.852 13.744 13.744 0 001.7-.6 13.889 13.889 0 001.586-.909 14.181 14.181 0 001.221-1.371c.429-.436 1.263-.53 1.633-1.019a15.631 15.631 0 00.816-1.708 14.265 14.265 0 00.774-1.687 14.514 14.514 0 00.775-1.71 14.986 14.986 0 00.232-1.865c.081-.598-.347-1.23-.347-1.856z" data-name="Path 21915"/>
      <path id="Path_21916" fill="#fff" d="M409.5 303.844l.942-.008a.629.629 0 00-.622.633l.059 6.9a.63.63 0 00.633.622l-.942.008a.628.628 0 01-.632-.622l-.06-6.9a.63.63 0 01.622-.633" data-name="Path 21916"/>
      <g id="Group_6570" data-name="Group 6570">
        <g id="Group_6566" data-name="Group 6566">
          <path id="Path_21917" d="M419.266 302.554a.285.285 0 01-.258-.218.276.276 0 01.192-.348l1.74-.491-.491-1.739a.308.308 0 01.194-.377.32.32 0 01.346.222l.568 2.009a.253.253 0 01-.008.207.3.3 0 01-.184.14l-2.009.569a.112.112 0 01-.09.026z" className="cls-3" data-name="Path 21917"/>
          <path id="Path_21918" d="M411.808 301.591a.285.285 0 01-.259-.189.294.294 0 01.164-.378 10.319 10.319 0 019.7.4.292.292 0 11-.259.523 9.817 9.817 0 00-9.23-.385.177.177 0 01-.116.029z" className="cls-3" data-name="Path 21918"/>
        </g>
        <g id="Group_6567" data-name="Group 6567">
          <path id="Path_21919" d="M412.861 319.98a.285.285 0 01-.269-.205l-.668-1.978a.235.235 0 01.027-.238.306.306 0 01.177-.149l1.978-.669a.3.3 0 01.212.561l-1.712.577.577 1.713a.308.308 0 01-.174.386z" className="cls-3" data-name="Path 21919"/>
          <path id="Path_21920" d="M416.94 319.145a10.332 10.332 0 01-4.867-1.172.292.292 0 01.233-.535 9.814 9.814 0 009.238-.08.292.292 0 01.241.531 10.325 10.325 0 01-4.845 1.256z" className="cls-3" data-name="Path 21920"/>
        </g>
        <g id="Group_6568" data-name="Group 6568">
          <path id="Path_21921" fill="#fbe57f" d="M416.384 311.983l-6.018.052a.545.545 0 01-.552-.536l-.051-5.95a.545.545 0 01.537-.549l6.019-.052a.547.547 0 01.552.537l.051 5.95a.545.545 0 01-.543.545" data-name="Path 21921"/>
          <path id="Path_21922" d="M416.041 312.146l-6.514.056a.873.873 0 01-.9-.881l-.056-6.514a.874.874 0 01.881-.9l6.514-.056a.873.873 0 01.9.881l.056 6.514a.873.873 0 01-.881.9zm-6.58-7.642a.278.278 0 00-.293.3l.056 6.514a.28.28 0 00.3.294l6.514-.056a.281.281 0 00.294-.3l-.056-6.514a.28.28 0 00-.3-.293z" className="cls-3" data-name="Path 21922"/>
        </g>
        <g id="Group_6569" data-name="Group 6569">
          <path id="Path_21923" fill="#72cac2" d="M425.507 315.567l-6.019.052a.547.547 0 01-.552-.537l-.051-5.95a.544.544 0 01.542-.545l6.019-.052a.546.546 0 01.552.536l.051 5.95a.545.545 0 01-.542.546" data-name="Path 21923"/>
          <path id="Path_21924" d="M425.466 315.727l-6.514.056a.871.871 0 01-.895-.88l-.057-6.514a.871.871 0 01.88-.9l6.514-.056a.871.871 0 01.9.88l.056 6.514a.872.872 0 01-.884.9zm-6.58-7.642a.279.279 0 00-.293.3l.056 6.514a.28.28 0 00.3.294l6.514-.056a.281.281 0 00.294-.3l-.056-6.514a.28.28 0 00-.3-.293z" className="cls-3" data-name="Path 21924"/>
        </g>
      </g>
    </g>
  </g>
</svg>
</div>