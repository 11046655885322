import * as React from 'react'
import './bulk-archive.css'

export const BulkArchive = (props: any) =>
<svg xmlns="http://www.w3.org/2000/svg" id="prefix__Component_29_1" width={props.svgwidth} height={props.svgheight} className="panel-icon" viewBox="0 0 30.15 24.001">
    <g id="prefix__Group_6684" data-name="Group 6684" transform="translate(-506.043 -297.801)">
        <path id="prefix__Path_23778" d="M533.336 307.263a3.334 3.334 0 0 0-1.586-.447 3.221 3.221 0 0 0-2.333 1 6.954 6.954 0 0 1 .9.086 3.221 3.221 0 0 1 2.1-.771 3.284 3.284 0 0 1 .919.132z" className="bulk-archive-1" data-name="Path 23778"/>
        <path id="prefix__Path_23779" d="M533.909 307.664l.012.011z" className="bulk-archive-1" data-name="Path 23779"/>
        <path id="prefix__Path_23780" d="M533.464 307.335c.054.034.106.071.158.107-.052-.042-.104-.073-.158-.107z" className="bulk-archive-1" data-name="Path 23780"/>
        <path id="prefix__Path_23781" d="M534.749 308.814a4.941 4.941 0 0 1-1.071.615 4.933 4.933 0 0 0 1.07-.615z" className="bulk-archive-1" data-name="Path 23781"/>
        <path id="prefix__Path_23782" d="M511.1 302.165l.021.024z" className="bulk-archive-1" data-name="Path 23782"/>
        <path id="prefix__Path_23783" d="M508.357 309.483a4.231 4.231 0 0 1-.548-.358 3.232 3.232 0 0 1 3-2 3.316 3.316 0 0 1 .933.136 3.35 3.35 0 0 0-1.6-.451 3.23 3.23 0 0 0-3 2 4.613 4.613 0 0 0 1.211.67 4.952 4.952 0 0 0 1.859.336 4.942 4.942 0 0 1-1.855-.333z" className="bulk-archive-1" data-name="Path 23783"/>
        <path id="prefix__Path_23784" d="M519.638 306.425a3.869 3.869 0 0 1-1.908-3.323 3.661 3.661 0 0 1 .034-.662 3.745 3.745 0 0 1 4.232-3.185 3.707 3.707 0 0 1 1.2.4 3.9 3.9 0 0 0-2.24-.744 3.872 3.872 0 0 0-1.31 7.517z" className="bulk-archive-1" data-name="Path 23784"/>
        <path id="prefix__Path_23785" d="M509.841 304.448a.794.794 0 0 1-.418-.992 1.387 1.387 0 0 1 1.384-1.386 1.324 1.324 0 0 1 .24.048 1.365 1.365 0 0 0-.9-.356h-.02a1.375 1.375 0 0 0-1.364 1.386 1.352 1.352 0 0 0 1.078 1.3z" className="bulk-archive-1" data-name="Path 23785"/>
        <path id="prefix__Path_23786" d="M511.838 307.321c.07.042.137.09.2.138-.063-.049-.13-.095-.2-.138z" className="bulk-archive-1" data-name="Path 23786"/>
        <path id="prefix__Path_23787" d="M512.288 307.649c.021.018.04.038.06.057-.02-.019-.039-.039-.06-.057z" className="bulk-archive-1" data-name="Path 23787"/>
        <path id="prefix__Path_23788" d="M513.531 314.914a11.061 11.061 0 0 0 7.787 2.826 10.66 10.66 0 0 1-3.778-.65 10.551 10.551 0 0 1-2.724-1.708 7.407 7.407 0 0 1 7.091-5.131 7.531 7.531 0 0 1 1.782.235c.1-.13.209-.254.319-.376a7.675 7.675 0 0 0-10.477 4.8z" className="bulk-archive-1" data-name="Path 23788"/>
        <path id="prefix__Path_23789" d="M532.714 302.174l.013.013z" className="bulk-archive-1" data-name="Path 23789"/>
        <path id="prefix__Path_23790" d="M531.5 304.485a1.349 1.349 0 0 0 .253.051 1.39 1.39 0 0 0 1.388-1.388 1.389 1.389 0 0 1-1.387 1.384 1.351 1.351 0 0 1-.253-.051 1.369 1.369 0 0 1-.47-1.024 1.387 1.387 0 0 1 1.386-1.386 1.293 1.293 0 0 1 .213.043 1.405 1.405 0 0 0-.876-.351 1.387 1.387 0 0 0-1.384 1.387 1.367 1.367 0 0 0 1.13 1.335z" className="bulk-archive-1" data-name="Path 23790"/>
        <path id="prefix__Path_23791" d="M509.423 303.456a.794.794 0 0 0 .418.992 1.315 1.315 0 0 0 .28.058 1.369 1.369 0 0 0 1-2.317l-.021-.024c-.018-.017-.038-.03-.056-.047a1.324 1.324 0 0 0-.24-.048 1.387 1.387 0 0 0-1.381 1.386z" className="bulk-archive-2" data-name="Path 23791"/>
        <path id="prefix__Path_23792" d="M507.809 309.125a4.231 4.231 0 0 0 .548.358 4.953 4.953 0 0 0 4.787-.67 3.343 3.343 0 0 0-.8-1.107c-.02-.019-.039-.039-.06-.057-.078-.069-.162-.129-.246-.19-.067-.048-.134-.1-.2-.138-.033-.02-.067-.038-.1-.057a3.316 3.316 0 0 0-.933-.136 3.232 3.232 0 0 0-2.996 1.997z" className="bulk-archive-2" data-name="Path 23792"/>
        <path id="prefix__Path_23793" d="M510.145 305.487a2.338 2.338 0 1 0-2.336-2.34 2.343 2.343 0 0 0 2.336 2.34zm-.02-3.725h.02a1.365 1.365 0 0 1 .9.356c.018.017.038.03.056.047l.021.024a1.369 1.369 0 0 1-1 2.317 1.315 1.315 0 0 1-.28-.058 1.352 1.352 0 0 1-1.08-1.3 1.375 1.375 0 0 1 1.363-1.386z" className="bulk-archive-3" data-name="Path 23793"/>
        <path id="prefix__Path_23794" d="M514.25 309.113l-.089-.289a4.2 4.2 0 0 0-8.029 0l-.089.289.227.2a5.794 5.794 0 0 0 7.755 0zm-5.895.37a4.613 4.613 0 0 1-1.211-.67 3.23 3.23 0 0 1 3-2 3.35 3.35 0 0 1 1.6.451l.1.057c.07.043.137.089.2.138.084.061.168.121.246.19.021.018.04.038.06.057a3.343 3.343 0 0 1 .8 1.107 4.957 4.957 0 0 1-4.789.67z" className="bulk-archive-3" data-name="Path 23794"/>
        <path id="prefix__Path_23795" d="M531.027 303.456a1.369 1.369 0 0 0 .47 1.024 1.351 1.351 0 0 0 .253.051 1.389 1.389 0 0 0 1.387-1.384 1.417 1.417 0 0 0-.41-.96l-.013-.013a1.044 1.044 0 0 0-.088-.061 1.293 1.293 0 0 0-.213-.043 1.387 1.387 0 0 0-1.386 1.386z" className="bulk-archive-2" data-name="Path 23795"/>
        <path id="prefix__Path_23796" d="M533.921 307.675l-.012-.011a3.392 3.392 0 0 0-.287-.222c-.052-.036-.1-.073-.158-.107s-.085-.048-.128-.072a3.284 3.284 0 0 0-.924-.135 3.221 3.221 0 0 0-2.1.771 6.975 6.975 0 0 1 3.365 1.53 4.941 4.941 0 0 0 1.071-.615 3.357 3.357 0 0 0-.827-1.139z" className="bulk-archive-2" data-name="Path 23796"/>
        <path id="prefix__Path_23797" d="M531.75 305.487a2.338 2.338 0 1 0-2.336-2.34 2.341 2.341 0 0 0 2.336 2.34zm0-3.725a1.405 1.405 0 0 1 .876.351 1.044 1.044 0 0 1 .088.061l.013.013a1.417 1.417 0 0 1 .41.96 1.39 1.39 0 0 1-1.388 1.388 1.349 1.349 0 0 1-.253-.051 1.367 1.367 0 0 1-1.131-1.336 1.387 1.387 0 0 1 1.385-1.386z" className="bulk-archive-3" data-name="Path 23797"/>
        <path id="prefix__Path_23798" d="M529.417 307.813a3.221 3.221 0 0 1 2.333-1 3.334 3.334 0 0 1 1.586.447c.043.024.086.046.128.072s.106.07.158.107a3.392 3.392 0 0 1 .287.222l.012.011a3.357 3.357 0 0 1 .828 1.139 4.933 4.933 0 0 1-1.07.615 7.057 7.057 0 0 1 .721.7 5.722 5.722 0 0 0 1.228-.823l.227-.2-.091-.289a4.2 4.2 0 0 0-7.606-.938 7.049 7.049 0 0 1 1.034-.085c.076.009.15.019.225.022z" className="bulk-archive-3" data-name="Path 23798"/>
        <path id="prefix__Path_23799" d="M517.76 302.44a3.661 3.661 0 0 0-.034.662 3.869 3.869 0 0 0 1.908 3.323h.01a3.8 3.8 0 0 0 1.3.242 3.86 3.86 0 0 0 2.24-7.018 3.707 3.707 0 0 0-1.2-.4 3.745 3.745 0 0 0-4.224 3.191z" className="bulk-archive-2" data-name="Path 23799"/>
        <path id="prefix__Path_23800" d="M514.816 315.382a10.551 10.551 0 0 0 2.724 1.708 10.66 10.66 0 0 0 3.778.65 11.169 11.169 0 0 0 1.46-.14 7.4 7.4 0 0 1-.586-2.8 6.853 6.853 0 0 1 .058-.852 6.945 6.945 0 0 1 1.439-3.464 7.531 7.531 0 0 0-1.782-.235 7.407 7.407 0 0 0-7.091 5.133z" className="bulk-archive-2" data-name="Path 23800"/>
        <path id="prefix__Path_23801" d="M520.948 307.781a4.99 4.99 0 1 0-4.989-4.991 4.99 4.99 0 0 0 4.989 4.991zm-3.654-6.3a3.873 3.873 0 0 1 3.654-2.577 3.881 3.881 0 0 1 0 7.762 3.8 3.8 0 0 1-1.3-.242h-.01a3.872 3.872 0 0 1-2.344-4.939z" className="bulk-archive-3" data-name="Path 23801"/>
        <path id="prefix__Path_23802" d="M522.778 317.6a11.215 11.215 0 0 1-9.247-2.686 7.675 7.675 0 0 1 10.477-4.8 6.97 6.97 0 0 1 .9-.835 8.857 8.857 0 0 0-12.539 5.673l-.084.316.238.225a12.183 12.183 0 0 0 10.814 3.14 7.082 7.082 0 0 1-.559-1.033z" className="bulk-archive-3" data-name="Path 23802"/>
        <path id="prefix__Path_23803" d="M529.192 308.8a6 6 0 1 0 6 6 6.007 6.007 0 0 0-6-6zm3.279 6.611l-2.884 2.757a.529.529 0 0 1-.719 0l-2.879-2.757a.362.362 0 0 1-.054-.057.4.4 0 0 1 .079-.561.542.542 0 0 1 .7.022l2.038 1.941v-4.786a.5.5 0 0 1 .984 0v4.786l2.023-1.941a.535.535 0 0 1 .692-.022.412.412 0 0 1 .057.054.4.4 0 0 1-.037.566z" className="bulk-archive-4" data-name="Path 23803"/>
        <path id="prefix__Path_23804" d="M536.193 314.8a6.964 6.964 0 0 0-1.792-4.665 7.057 7.057 0 0 0-.721-.7 7.01 7.01 0 0 0-4.261-1.616c-.075 0-.149-.012-.224-.012a7.049 7.049 0 0 0-1.034.085 6.965 6.965 0 0 0-4.151 2.224c-.11.122-.218.246-.319.376a6.945 6.945 0 0 0-1.439 3.464 7 7 0 1 1 7.928 7.774 7 7 0 0 0 6.013-6.93z" className="bulk-archive-4" data-name="Path 23804"/>
        <path id="prefix__Path_23805" d="M522.286 315.892a7 7 0 0 0 .492 1.708 7.082 7.082 0 0 0 .558 1.029 6.994 6.994 0 0 0 4.67 3.064 7.006 7.006 0 0 1-5.72-5.801z" className="bulk-archive-4" data-name="Path 23805"/>
        <path id="prefix__Path_23806" d="M536.192 314.8a7 7 0 0 0-13.942-.852 6.853 6.853 0 0 0-.058.852 7 7 0 0 0 14 0zm-7 6a6 6 0 1 1 6-6 6.007 6.007 0 0 1-6 6z" className="bulk-archive-3" data-name="Path 23806"/>
        <path id="prefix__Path_23807" d="M532.451 314.8a.535.535 0 0 0-.692.022l-2.023 1.941v-4.786a.5.5 0 0 0-.984 0v4.786l-2.038-1.941a.542.542 0 0 0-.7-.022.4.4 0 0 0-.079.561.362.362 0 0 0 .054.057l2.879 2.757a.529.529 0 0 0 .719 0l2.884-2.757a.4.4 0 0 0 .037-.564.412.412 0 0 0-.057-.054z" className="bulk-archive-3" data-name="Path 23807"/>
    </g>
</svg>
