import * as React from 'react'
import './aiknowledge.css'

export const AiKnowledge = (props: any) =>
<svg xmlns="http://www.w3.org/2000/svg" width={props.svgweight} height={props.svgheight} className="panel-icon" viewBox="0 0 26.184 21.877">
    <g id="Group_6286" data-name="Group 6286" transform="translate(-43.226 -686.997)">
        <g id="Group_6269" data-name="Group 6269">
            <g id="Group_6258" data-name="Group 6258" transform="translate(43.226 686.997)">
                <path id="Path_12151" d="M650.266 476.061a2.421 2.421 0 0 0-2.418-2.418h-.02l.111.542.571.28.044.021.019.01.01.005a1.736 1.736 0 0 1 .38.249l.037.031a1.705 1.705 0 0 1 .3.351l.006.011a1.721 1.721 0 0 1 .176.384.867.867 0 0 1 .028.089 1.683 1.683 0 0 1 .065.443 1.729 1.729 0 0 1-1.727 1.727h-15.2a1.725 1.725 0 0 1-.756-3.277l.863-.423-.1-.956a1.715 1.715 0 0 1-.01-.179 2.074 2.074 0 0 1 2.072-2.072 2.051 2.051 0 0 1 1.1.324l1.115.71.195-.279a2.754 2.754 0 0 0-5.177 1.317 2.7 2.7 0 0 0 .044.471l.048.28-.265.1a2.416 2.416 0 0 0 .864 4.674h15.2a2.421 2.421 0 0 0 2.425-2.415z" className="ai-1" data-name="Path 12151" transform="translate(-629.542 -468.117)"/>
                <path id="Path_12152" d="M639.835 469.582a3.114 3.114 0 0 1 2.708 1.589l.573 1.016 1.1-.395a2.391 2.391 0 0 1 .8-.138 2.421 2.421 0 0 1 2.19 1.411c.077-.005.151-.03.228-.03a3.067 3.067 0 0 1 .527.049 3.117 3.117 0 0 0-2.945-2.121 3.084 3.084 0 0 0-1.288.282l-.311.143-.146-.31a3.787 3.787 0 0 0-6.567-.526 3.572 3.572 0 0 1 .455.543l.127-.181a3.116 3.116 0 0 1 2.549-1.332z" className="ai-1" data-name="Path 12152" transform="translate(-629.128 -468.2)"/>
                <path id="Path_12153" d="M647.472 474.24l-.019-.01.029.015z" className="ai-2" data-name="Path 12153" transform="translate(-628.441 -467.858)"/>
                <path id="Path_12155" d="M640.361 486.96a1 1 0 0 1-.022-.112 1.017 1.017 0 0 1 .338-.748l.347-.944a1.729 1.729 0 0 0-1.377 1.692 1.709 1.709 0 0 0 .419 1.115 1.057 1.057 0 0 1 .057-.357z" className="ai-3" data-name="Path 12155" transform="translate(-628.94 -467.16)"/>
                <path id="Path_12156" d="M638.045 487.112a.345.345 0 1 0-.346-.345.346.346 0 0 0 .346.345z" className="ai-4" data-name="Path 12156" transform="translate(-629.064 -467.079)"/>
                <path id="Path_12157" d="M638.473 485.156v.719a1.036 1.036 0 1 1-.692 0v-.719a1.727 1.727 0 1 0 .692 0z" className="ai-3" data-name="Path 12157" transform="translate(-629.147 -467.16)"/>
                <path id="Path_12158" d="M632.609 486.588a1.036 1.036 0 1 1 .972-1.382h.719a1.727 1.727 0 1 0 0 .692h-.719a1.033 1.033 0 0 1-.972.69z" className="ai-3" data-name="Path 12158" transform="translate(-629.5 -467.245)"/>
                <circle id="Ellipse_139" cx=".345" cy=".345" r=".345" className="ai-4" data-name="Ellipse 139" transform="translate(2.763 17.961)"/>
                <path id="Path_12159" d="M644.435 479.592v-.3h3.454a3.106 3.106 0 0 0 1.309-5.925v-.016a3.809 3.809 0 0 0-3.724-3.04 3.736 3.736 0 0 0-1.269.22 4.484 4.484 0 0 0-7.592-.371 3.448 3.448 0 0 0-5.3 2.914c0 .1.005.21.016.319a3.107 3.107 0 0 0 1.366 5.9h3.453v1.727h.692v-1.727h1.381v4.835h.692v-4.835h2.763v.69h.69v-.69h1.382v1.288l.151-.411a1.053 1.053 0 0 1 .536-.578zm-11.743-.988a2.416 2.416 0 0 1-.864-4.674l.265-.1-.048-.28a2.7 2.7 0 0 1-.044-.471 2.763 2.763 0 0 1 5.526 0h.69a3.426 3.426 0 0 0-.6-1.941 3.577 3.577 0 0 0-.455-.543 3.787 3.787 0 0 1 6.567.526l.146.31.311-.143a3.083 3.083 0 0 1 1.288-.282 3.117 3.117 0 0 1 2.945 2.121 3.067 3.067 0 0 0-.527-.049c-.077 0-.151.024-.228.03a3.11 3.11 0 0 0-1.327.386l.346.6a2.42 2.42 0 0 1 1.188-.32h.02a2.418 2.418 0 0 1 0 4.836z" className="ai-5" data-name="Path 12159" transform="translate(-629.583 -468.241)"/>
                <path id="Path_12165" d="M640.319 486.9l.316-.86a1.017 1.017 0 0 0-.338.748 1 1 0 0 0 .022.112z" data-name="Path 12165" transform="translate(-628.898 -467.104)"/>
                <path id="Path_12170" d="M647.37 488.66h-.587l-.174-.561-.375-1.2h-1.677l-.384 1.206-.178.555h-3.623a1.066 1.066 0 0 0 .7.266h2.336a1.064 1.064 0 0 0 1.014-.74l.326-1.021h1.286l.316 1.014a1.064 1.064 0 0 0 1.016.748h2.438a1.063 1.063 0 0 0 .7-.266h-3.134z" className="ai-6" data-name="Path 12170" transform="translate(-628.894 -467.049)"/>
                <path id="Path_12171" d="M653.372 479.066h.685a1.049 1.049 0 0 0-.685-.266h-2.26a1.049 1.049 0 0 0-.684.266h2.944z" className="ai-6" data-name="Path 12171" transform="translate(-628.251 -467.566)"/>
                <path id="Path_12172" d="M653.945 479.157v9.885a1.049 1.049 0 0 0 .266-.685v-8.515a1.048 1.048 0 0 0-.266-.685z" className="ai-6" data-name="Path 12172" transform="translate(-628.026 -467.544)"/>
                <path id="Path_12173" d="M650.978 479.864v-.684a1.048 1.048 0 0 0-.266.684v7.821l-2.892-8.175a1.066 1.066 0 0 0-1-.71h-2.592a1.125 1.125 0 0 0-1 .7l-.151.411-1.382 3.761-.69 1.88-.005.015-.347.944-.316.86-.237.646a1.188 1.188 0 0 0 .008.752l.243-.661 3.128-8.514.193-.523h3.712l.188.532 3.013 8.515.27.764a1.106 1.106 0 0 0 .126.187v-9.2z" className="ai-6" data-name="Path 12173" transform="translate(-628.915 -467.566)"/>
                <path id="Path_12174" d="M651.112 488.554h-.684a1.048 1.048 0 0 0 .684.266h2.26a1.049 1.049 0 0 0 .685-.266h-2.945z" className="ai-6" data-name="Path 12174" transform="translate(-628.251 -466.943)"/>
                <path id="Path_12175" d="M644.162 479.8l-3.128 8.515h2.336l.562-1.762h2.847l.549 1.762h2.438l-3.013-8.515zm.23 5.107l.983-3.127.945 3.127z" className="ai-4" data-name="Path 12175" transform="translate(-628.851 -467.503)"/>
                <path id="Rectangle_2111" d="M0 0h2.26v8.515H0z" className="ai-4" data-name="Rectangle 2111" transform="translate(22.861 12.298)"/>
                <g id="Group_6261" data-name="Group 6261">
                    <path id="Path_12154" d="M635.785 471.245a2.051 2.051 0 0 0-1.1-.325v.692a1.383 1.383 0 0 0-1.382 1.381h-.69a1.716 1.716 0 0 0 .01.179l.1.956-.863.423a1.743 1.743 0 0 0-.97 1.55h.69a1.037 1.037 0 0 0 1.036 1.036v.69h15.2v-.69a1.036 1.036 0 0 0 0-2.073v-.69a1.717 1.717 0 0 1 .662.133l-.571-.28-.111-.542a2.419 2.419 0 0 0-1.188.32l-.346-.6a3.111 3.111 0 0 1 1.327-.386 2.421 2.421 0 0 0-2.19-1.411 2.391 2.391 0 0 0-.8.138l-1.1.395-.573-1.016a3.1 3.1 0 0 0-5.257-.258l-.127.181a3.426 3.426 0 0 1 .6 1.941h-.69a2.738 2.738 0 0 0-.349-1.317l-.195.279zm7.185 5.547a1.037 1.037 0 0 0 1.036-1.036h.69a1.727 1.727 0 1 1-3.454 0h.692a1.037 1.037 0 0 0 1.037 1.036zm-.995-4.877a1.729 1.729 0 0 1 0 2.443l-.488-.489a1.036 1.036 0 1 0-1.466-1.465l-.488-.488a1.729 1.729 0 0 1 2.443 0zm-4.532 3.841h-.69a1.036 1.036 0 0 0-2.072 0h-.692a1.727 1.727 0 0 1 3.454 0z" className="ai-7" data-name="Path 12154" transform="translate(-629.5 -468.158)"/>
                    <path id="Path_12160" d="M631.572 475.708h-.69a1.729 1.729 0 0 0 1.727 1.727v-.69a1.037 1.037 0 0 1-1.037-1.037z" className="ai-8" data-name="Path 12160" transform="translate(-629.5 -467.764)"/>
                    <path id="Path_12161" d="M634.577 471.53v-.692a2.074 2.074 0 0 0-2.072 2.072h.69a1.383 1.383 0 0 1 1.382-1.38z" className="ai-8" data-name="Path 12161" transform="translate(-629.396 -468.075)"/>
                    <path id="Path_12162" d="M636.712 480.9h-.692v3.453a1.038 1.038 0 0 1-1.036 1.036h-1.444a1.036 1.036 0 1 0 0 .692h1.445a1.73 1.73 0 0 0 1.728-1.728zm-4.145 5.181a.346.346 0 1 1 .345-.346.346.346 0 0 1-.345.349z" className="ai-8" data-name="Path 12162" transform="translate(-629.459 -467.432)"/>
                    <path id="Path_12163" d="M637.05 486.934a1.036 1.036 0 1 0 1.382-.973v-2.135h-.692v2.135a1.037 1.037 0 0 0-.69.973zm1.036-.346a.345.345 0 1 1-.346.346.346.346 0 0 1 .346-.346z" className="ai-8" data-name="Path 12163" transform="translate(-629.106 -467.245)"/>
                    <path id="Path_12164" d="M641.637 479.929h-.691v5.548l.691-1.88z" className="ai-8" data-name="Path 12164" transform="translate(-628.857 -467.494)"/>
                    <path id="Path_12166" d="M640.968 472.292a1.036 1.036 0 0 1 0 1.465l.488.489a1.727 1.727 0 0 0-2.443-2.443l.488.488a1.038 1.038 0 0 1 1.467.001z" className="ai-8" data-name="Path 12166" transform="translate(-628.98 -468.046)"/>
                    <path id="Path_12167" d="M646.79 474.775a1.036 1.036 0 0 1 0 2.072v.69a1.729 1.729 0 0 0 1.727-1.727 1.684 1.684 0 0 0-.065-.443.866.866 0 0 0-.028-.089 1.726 1.726 0 0 0-.176-.384l-.006-.011a1.7 1.7 0 0 0-.3-.351l-.037-.031a1.733 1.733 0 0 0-.38-.249l-.029-.015-.044-.021a1.717 1.717 0 0 0-.662-.133z" className="ai-8" data-name="Path 12167" transform="translate(-628.484 -467.868)"/>
                    <path id="Path_12168" d="M641.312 475.383h-.691a1.727 1.727 0 1 0 3.454 0h-.69a1.036 1.036 0 0 1-2.073 0z" className="ai-8" data-name="Path 12168" transform="translate(-628.878 -467.785)"/>
                    <path id="Path_12169" d="M633.8 475.487h.692a1.036 1.036 0 1 1 2.072 0h.691a1.727 1.727 0 0 0-3.455 0z" className="ai-8" data-name="Path 12169" transform="translate(-629.313 -467.888)"/>
                    <path id="Path_12176" d="M647.574 479.582l-.188-.532h-3.712l-.193.523-3.128 8.514-.243.661-.152.413h4.063l.178-.555.384-1.206h1.677l.375 1.2.174.561h4.154l-.106-.3-.27-.764zm-.178 8.781l-.549-1.762H644l-.562 1.762H641.1l3.128-8.515h2.592l3.013 8.515z" className="ai-8" data-name="Path 12176" transform="translate(-628.92 -467.55)"/>
                    <path id="Path_12177" d="M644.19 484.788h1.927l-.944-3.127z" className="ai-8" data-name="Path 12177" transform="translate(-628.65 -467.384)"/>
                    <path id="Path_12178" d="M654.177 479.848v-.8h-3.856v10.111h3.856v-9.313zm-.8 8.515h-2.26v-8.515h2.26z" className="ai-8" data-name="Path 12178" transform="translate(-628.258 -467.55)"/>
                </g>
            </g>
        </g>
    </g>
</svg>
