import * as React from 'react'

export const AccountAndBillingSetting = (props: any) =>
  <svg width={props.svgwidth} height={props.svgheight} viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15 34.2298V31.8562C15 31.0987 15.295 30.3722 15.8201 29.8366C16.3452 29.3009 17.0574 29 17.8 29H26.2C26.9426 29 27.6548 29.3009 28.1799 29.8366C28.705 30.3722 29 31.0987 29 31.8562V34.2298"
        fill="#B8E5E1"/>
      <path
        d="M15 34.2298V31.8562C15 31.0987 15.295 30.3722 15.8201 29.8366C16.3452 29.3009 17.0574 29 17.8 29H26.2C26.9426 29 27.6548 29.3009 28.1799 29.8366C28.705 30.3722 29 31.0987 29 31.8562V34.2298C23.1365 37.4055 17.2784 35.5948 15.0823 34.2924"
        stroke="#1E4E69" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <rect x="16" y="32" width="12" height="4" fill="#B8E5E1"/>
      <path
        d="M22 36.3333C29.824 36.3333 36.1666 29.9907 36.1666 22.1667C36.1666 14.3426 29.824 8 22 8C14.1759 8 7.83331 14.3426 7.83331 22.1667C7.83331 29.9907 14.1759 36.3333 22 36.3333Z"
        stroke="#1E4E69" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path
        d="M22 23.583C24.3472 23.583 26.25 21.6802 26.25 19.333C26.25 16.9858 24.3472 15.083 22 15.083C19.6528 15.083 17.75 16.9858 17.75 19.333C17.75 21.6802 19.6528 23.583 22 23.583Z"
        fill="#B8E5E1" stroke="#1E4E69" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
