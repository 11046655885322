import * as React from 'react'
import './attention.css'

export const AttentionIcon = (props: any) => {
  return <svg id="attention" className="attention-logo" version="1.0" xmlns="http://www.w3.org/2000/svg"
    width={props.svgwidth} height={props.svgheight} viewBox="0 0 385.000000 385.000000"
    preserveAspectRatio="xMidYMid meet">
    <title>{props.title}</title>
    <g id="attention-1" className="attention-1" transform="translate(0.000000,385.000000) scale(0.100000,-0.100000)"
      stroke="none">
      <path d="M1664 3824 c-539 -73 -1033 -385 -1338 -844 -421 -634 -421 -1478 0
        -2110 361 -541 954 -860 1599 -860 645 0 1237 318 1599 860 380 566 421 1317
        106 1930 -248 481 -702 845 -1223 979 -229 59 -513 77 -743 45z m417 -225
        c582 -59 1087 -406 1347 -924 488 -975 -69 -2146 -1138 -2391 -298 -68 -619
        -50 -914 53 -549 191 -961 652 -1092 1223 -154 673 132 1378 717 1769 313 208
        712 308 1080 270z"/>
      <path d="M1893 3190 c-102 -21 -153 -77 -167 -185 -5 -41 57 -832 130 -1652
        l6 -73 74 0 73 0 5 32 c10 58 146 1631 146 1678 0 53 -26 114 -64 152 -43 42
        -128 62 -203 48z"/>
      <path d="M1840 1073 c-75 -40 -113 -96 -118 -176 -6 -97 37 -176 121 -218 50
        -26 140 -28 190 -4 58 27 113 94 126 153 40 189 -149 334 -319 245z"/>
    </g>
  </svg>
}
