import * as React from 'react'
import './analytics.css'

export const Analytics = (props: any) =>
<svg xmlns="http://www.w3.org/2000/svg" width={props.svgwidth} height={props.svgheight} viewBox="0 0 61.187 60.533">
    <g id="Group_6298" data-name="Group 6298" transform="translate(-255 -95)">
        <g id="Group_6297" data-name="Group 6297" transform="translate(7 -93)">
            <g id="Group_6296" data-name="Group 6296" transform="translate(248 188)">
                <g id="Ellipse_9" data-name="Ellipse 9">
                    <path id="Path_344-2" d="M168.639 245.032c1.346 0 2.719-.83 4.021-.663 1.331.174 2.43 1.361 3.7 1.7a28.988 28.988 0 0 1 3.617 1.417c1.233.5 2.8.241 3.942.895a30.743 30.743 0 0 1 3.111 2.464 30.4 30.4 0 0 1 2.669 2.869c.939.926 2.576 1.234 3.385 2.274a30.551 30.551 0 0 1 2.08 3.4c.663 1.13.594 2.6 1.107 3.818a28.824 28.824 0 0 1 1.54 3.579 29.36 29.36 0 0 1 .483 7.713c0 1.326.933 2.669.766 3.952a30.782 30.782 0 0 1-.509 3.957 30.835 30.835 0 0 1-1.69 3.625 30.1 30.1 0 0 1-1.69 3.579c-.667 1.138-.976 2.587-1.784 3.623s-2.628 1.236-3.566 2.161a31.026 31.026 0 0 1-2.665 2.909 30.389 30.389 0 0 1-3.461 1.921 29.762 29.762 0 0 1-3.719 1.269c-1.218.5-2.214 1.468-3.508 1.809a30.26 30.26 0 0 1-3.87.8 31.876 31.876 0 0 1-3.951-.249c-1.346 0-2.724.97-4.021.8a31.226 31.226 0 0 1-3.953-.786c-1.292-.341-2.286-1.523-3.505-2.022a30.242 30.242 0 0 1-3.645-1.5c-1.153-.658-2.743-.727-3.793-1.524s-1.224-2.6-2.161-3.527-2.856-1.023-3.672-2.061a31.6 31.6 0 0 1-2.169-3.4c-.661-1.129-.327-2.775-.837-3.993-.506-1.2-1.67-2.2-2.015-3.474a30.37 30.37 0 0 1-.963-3.879c-.17-1.281.746-2.664.746-3.993a30.3 30.3 0 0 1-.1-3.908c.173-1.313.943-2.455 1.282-3.708a28.055 28.055 0 0 1 1.393-3.587c.512-1.216.538-2.6 1.2-3.728a29.849 29.849 0 0 1 2.46-3.05c.807-1.037 1.073-2.545 2.01-3.471s2.352-1.326 3.4-2.125a30.025 30.025 0 0 1 3.085-2.486 30.38 30.38 0 0 1 3.511-1.9 31.493 31.493 0 0 1 3.9-.887c1.269-.336 2.457-1.311 3.788-1.481 1.302-.174 2.703.868 4.051.868z" className="analytics-1" data-name="Path 344-2" transform="translate(-137.892 -244.141)"/>
                </g>
                <path id="Path_12181" d="M186.59 291.581c-3.064 2.6-3.629.619-7.667.277s-3.7-.263-7.735-.6-3.379.1-7.42-.245-6.822.836-9.407-2.241c-2.643-3.143-1.756-3.613-1.413-7.67s.839-4.347 1.183-8.407-1.168-5.81 1.965-8.468c3.064-2.6 3.182-.611 7.22-.27s4.622-.051 8.659.291 4.084.984 8.124 1.326 5.937-1.4 8.524 1.672c2.642 3.143.755 3.709.412 7.765a25.13 25.13 0 0 0 .266 8.067c.559 4.308.423 5.845-2.711 8.503z" className="analytics-2" data-name="Path 12181" transform="translate(-141.092 -248.244)"/>
                <g id="Group_6295" data-name="Group 6295" transform="translate(16.653 18.361)">
                    <path id="Path_12182" d="M165.438 283.163a1.068 1.068 0 0 0 1.378 1.632l4.465-3.768-1.381-1.627zm20.539-9.353l-3.227.19a.632.632 0 0 0-.446 1.038l.491.583-3.63 2.645-2.606-3.087a1.067 1.067 0 0 0-1.5-.127l-.8.675 3.918 4.676a1.068 1.068 0 0 0 1.445.174l4.557-3.321.63.746a.632.632 0 0 0 1.1-.266l.725-3.15a.631.631 0 0 0-.657-.775z" className="analytics-3" data-name="Path 12182" transform="translate(-160.485 -268.989)"/>
                    <path id="Path_12183" d="M185.9 288.247l-24.47-2.071.413-4.894c.015-.011.032-.013.045-.025l6.877-5.8 8.4 9.954a1.067 1.067 0 0 0 1.434.181l4.94-3.511a1.068 1.068 0 1 0-1.237-1.741l-4.14 2.942-8.457-10.019a1.067 1.067 0 0 0-1.5-.127l-6.108 5.155.8-9.49a1.068 1.068 0 1 0-2.128-.18l-1.56 18.441a1.068 1.068 0 0 0 .974 1.154l25.535 2.16a1.068 1.068 0 1 0 .18-2.128z" className="analytics-4" data-name="Path 12183" transform="translate(-159.205 -267.64)"/>
                </g>
            </g>
        </g>
    </g>
</svg>
