import { GridCell } from '@progress/kendo-react-grid'
import { format, parseISO } from 'date-fns'
import React from 'react'
import { DATETIME_FORMAT } from 'shared/constants'

export class DateTimeCell extends GridCell {
  public render() {
    let dateValue: any = this.props.dataItem[this.props.field]
    if ( typeof dateValue === 'string' ) {
      dateValue = parseISO(dateValue)
    }
    return <td>
              <div>
                {dateValue ? format(dateValue, DATETIME_FORMAT) : ''}
              </div>
            </td>
  }
}
