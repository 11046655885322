import * as React from 'react'
import './books.css'

export const Books = (props: any) =>
  <svg width={props.svgwidth} height={props.svgheight} viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="44" height="44" fill="white"/>
    <path
      d="M16.4468 14.3189H9.90763C9.30572 14.3189 8.81778 14.8363 8.81778 15.4745V35.12C8.81778 35.7582 9.30572 36.2756 9.90763 36.2756H16.4468C17.0487 36.2756 17.5366 35.7582 17.5366 35.12V15.4745C17.5366 14.8363 17.0487 14.3189 16.4468 14.3189Z"
      fill="#72CAC2" fill-opacity="0.6" stroke="#1E4E69" strokeWidth="2" strokeLinejoin="round"/>
    <path d="M9 19H18M9 33H18" stroke="#1E4E69" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path
      d="M23.5201 7H18.7333C18.0724 7 17.5366 7.50412 17.5366 8.12599V35.1496C17.5366 35.7715 18.0724 36.2756 18.7333 36.2756H23.5201C24.181 36.2756 24.7168 35.7715 24.7168 35.1496V8.12599C24.7168 7.50412 24.181 7 23.5201 7Z"
      fill="#FBE57F" stroke="#1E4E69" strokeWidth="2" strokeLinejoin="round"/>
    <path
      d="M30.912 10.4518L27.3394 10.7501C26.3561 10.8322 25.6433 11.5623 25.7539 12.3716L28.8418 34.9457C28.9532 35.755 29.8473 36.3496 30.8306 36.2682L34.4032 35.9698C35.3865 35.8877 36.0992 35.1577 35.9887 34.3483L32.9052 11.7778C32.7893 10.9649 31.8953 10.3696 30.912 10.4518Z"
      fill="#FFB9B9" stroke="#1E4E69" strokeWidth="2" strokeLinejoin="round"/>
    <path d="M18 11H24M18 32H24" stroke="#1E4E69" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M26.8277 15.6674L32.6747 14.9303M28.9162 32.233L34.7632 31.4959" stroke="#1E4E69" strokeWidth="2"
          strokeLinecap="round" strokeLinejoin="round"/>
  </svg>

