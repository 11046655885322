import * as React from 'react'
import './addcontacts.css'

export const AddContacts = (props: any) =>
<svg xmlns="http://www.w3.org/2000/svg" width={props.svgwidth} height={props.svgheight} className="panel-icon" viewBox="0 0 44.344 33.105">
    <g id="Group_6354" data-name="Group 6354" transform="translate(-442.849 -510.895)">
        <g id="Group_6347" data-name="Group 6347">
            <g id="Group_6332" data-name="Group 6332" transform="translate(442.849 519.377)">
                <g id="Group_6325" data-name="Group 6325">
                    <path id="Path_21266" d="M663.911 178.245a4.513 4.513 0 0 1 3.822-8.143 4.493 4.493 0 0 0-2.3-.647 4.525 4.525 0 0 0-1.524 8.79z" className="addcontacts-1" data-name="Path 21266" transform="translate(-648.013 -168.16)"/>
                    <path id="Path_21267" d="M659.815 181.746a9.007 9.007 0 0 1 12.267-5.62 9.049 9.049 0 0 0-13.041 5.255 12.937 12.937 0 0 0 4.1 2.492 12.9 12.9 0 0 1-3.326-2.127z" className="addcontacts-1" data-name="Path 21267" transform="translate(-650.289 -161.381)"/>
                    <path id="Path_21268" d="M657.859 174.135a1.6 1.6 0 0 1-.549-1.2 1.621 1.621 0 0 1 1.617-1.62 1.511 1.511 0 0 1 .3.06 1.6 1.6 0 1 0-1.366 2.756z" className="addcontacts-1" data-name="Path 21268" transform="translate(-653.358 -166.324)"/>
                    <path id="Path_21269" d="M669.207 174.135a1.6 1.6 0 0 1-.549-1.2 1.621 1.621 0 0 1 1.62-1.62 1.455 1.455 0 0 1 .3.06 1.6 1.6 0 1 0-1.366 2.756z" className="addcontacts-1" data-name="Path 21269" transform="translate(-639.456 -166.324)"/>
                    <path id="Path_21270" d="M656.461 176.308a3.775 3.775 0 0 1 3.5-2.334 3.874 3.874 0 0 1 1.117.165 3.751 3.751 0 0 0-5.395 1.8 5.406 5.406 0 0 0 1.415.783 5.184 5.184 0 0 1-.637-.414z" className="addcontacts-1" data-name="Path 21270" transform="translate(-654.397 -163.073)"/>
                    <path id="Path_21271" d="M667.812 176.308a3.77 3.77 0 0 1 3.5-2.334 3.861 3.861 0 0 1 1.115.165 3.749 3.749 0 0 0-5.393 1.8 5.359 5.359 0 0 0 1.413.783 5.019 5.019 0 0 1-.635-.414z" className="addcontacts-1" data-name="Path 21271" transform="translate(-640.495 -163.073)"/>
                    <path id="Path_21272" d="M656.883 172.738a.938.938 0 0 0 .843 1.257 1.624 1.624 0 0 0 1.622-1.622 1.6 1.6 0 0 0-.549-1.195 1.513 1.513 0 0 0-.3-.06 1.621 1.621 0 0 0-1.616 1.62z" className="addcontacts-2" data-name="Path 21272" transform="translate(-652.932 -166.123)"/>
                    <path id="Path_21273" d="M656.035 176.107a5.188 5.188 0 0 0 .641.418 5.8 5.8 0 0 0 5.6-.783 3.763 3.763 0 0 0-1.617-1.8 3.875 3.875 0 0 0-1.117-.165 3.775 3.775 0 0 0-3.507 2.33z" className="addcontacts-2" data-name="Path 21273" transform="translate(-653.97 -162.872)"/>
                    <path id="Path_21274" d="M658.765 175.92a2.733 2.733 0 1 0-2.73-2.734 2.738 2.738 0 0 0 2.73 2.734zm0-4.354a1.659 1.659 0 0 1 1.622 1.62 1.624 1.624 0 0 1-1.622 1.622 1.854 1.854 0 0 1-1.617-1.622 1.621 1.621 0 0 1 1.617-1.62z" className="addcontacts-3" data-name="Path 21274" transform="translate(-653.97 -166.937)"/>
                    <path id="Path_21275" d="M664.7 176.907l-.1-.338a4.912 4.912 0 0 0-9.384 0l-.1.338.265.238a6.772 6.772 0 0 0 9.063 0zm-4.8-2.685a3.923 3.923 0 0 1 3.508 2.334 5.8 5.8 0 0 1-5.6.783 5.4 5.4 0 0 1-1.415-.783 3.775 3.775 0 0 1 3.507-2.335z" className="addcontacts-3" data-name="Path 21275" transform="translate(-655.107 -163.685)"/>
                    <path id="Path_21276" d="M668.233 172.738a1.6 1.6 0 0 0 .549 1.2 1.661 1.661 0 0 0 .3.06 1.625 1.625 0 0 0 1.622-1.622 1.6 1.6 0 0 0-.552-1.195 1.454 1.454 0 0 0-.3-.06 1.621 1.621 0 0 0-1.619 1.617z" className="addcontacts-4" data-name="Path 21276" transform="translate(-639.031 -166.123)"/>
                    <path id="Path_21277" d="M667.386 176.107a5.016 5.016 0 0 0 .639.418 5.8 5.8 0 0 0 5.6-.783 3.741 3.741 0 0 0-1.618-1.8 3.86 3.86 0 0 0-1.115-.165 3.771 3.771 0 0 0-3.506 2.33z" className="addcontacts-4" data-name="Path 21277" transform="translate(-640.068 -162.872)"/>
                    <path id="Path_21278" d="M670.116 175.92a2.733 2.733 0 1 0-2.73-2.734 2.738 2.738 0 0 0 2.73 2.734zm0-4.354a1.663 1.663 0 0 1 1.622 1.62 1.625 1.625 0 0 1-1.622 1.622 1.666 1.666 0 0 1-.3-.06 1.6 1.6 0 0 1-1.321-1.562 1.621 1.621 0 0 1 1.621-1.62z" className="addcontacts-3" data-name="Path 21278" transform="translate(-640.068 -166.937)"/>
                    <path id="Path_21279" d="M675.944 176.568a4.91 4.91 0 0 0-9.382 0l-.1.338.265.238a6.769 6.769 0 0 0 9.061 0l.265-.238zm-4.692-2.347a3.924 3.924 0 0 1 3.506 2.334 5.8 5.8 0 0 1-5.6.783 5.359 5.359 0 0 1-1.412-.783 3.775 3.775 0 0 1 3.506-2.334z" className="addcontacts-3" data-name="Path 21279" transform="translate(-641.205 -163.685)"/>
                    <path id="Path_21280" d="M661.247 174.155a4.521 4.521 0 0 0 2.238 3.889 4.455 4.455 0 0 0 1.524.283 4.377 4.377 0 1 0-3.762-4.171z" className="addcontacts-5" data-name="Path 21280" transform="translate(-647.587 -167.959)"/>
                    <path id="Path_21281" d="M659.389 181.152a12.365 12.365 0 0 0 3.184 2 12.55 12.55 0 0 0 12.653-2.338 8.535 8.535 0 0 0-4.109-4.931 8.767 8.767 0 0 0-3.439-.724 8.659 8.659 0 0 0-8.289 5.993z" className="addcontacts-5" data-name="Path 21281" transform="translate(-649.134 -160.604)"/>
                    <path id="Path_21282" d="M666.148 180.537a5.832 5.832 0 1 0-5.831-5.833 5.839 5.839 0 0 0 5.831 5.833zm0-10.369a4.589 4.589 0 0 1 4.536 4.536 4.539 4.539 0 0 1-4.536 4.536 4.455 4.455 0 0 1-1.524-.283 4.525 4.525 0 0 1 1.524-8.79z" className="addcontacts-3" data-name="Path 21282" transform="translate(-648.726 -168.873)"/>
                    <path id="Path_21283" d="M668.513 174.407a10.377 10.377 0 0 0-10.029 7.729l-.1.369.278.262a14.254 14.254 0 0 0 19.7 0l.276-.262-.1-.369a10.373 10.373 0 0 0-10.025-7.729zm0 1.3a9.132 9.132 0 0 1 8.67 6.392 12.976 12.976 0 0 1-17.34 0 9.079 9.079 0 0 1 8.67-6.399z" className="addcontacts-3" data-name="Path 21283" transform="translate(-651.091 -162.095)"/>
                </g>
            </g>
            <g id="Group_6334" data-name="Group 6334" transform="translate(7 -328)">
                <path id="Path_12081" d="M373.087 251.346a11.412 11.412 0 1 0 11.413 11.413 11.425 11.425 0 0 0-11.413-11.413z" className="addcontacts-6" data-name="Path 12081" transform="translate(95.194 588.049)"/>
                <g id="Group_5487" data-name="Group 5487" transform="translate(462.869 844.951)">
                    <path id="Path_12082" d="M371.191 254.522v4.93h4.741v1.26h-4.741v4.972h-1.344v-4.972h-4.742v-1.26h4.742v-4.93z" className="addcontacts-7" data-name="Path 12082" transform="translate(-365.105 -254.522)"/>
                </g>
            </g>
        </g>
    </g>
</svg>
