import * as React from 'react'
import './americanexpress.css'

export const AmericanExpressIcon = (props: any) =>
<svg xmlns="http://www.w3.org/2000/svg" width="33.386" height="23.997" viewBox="0 0 33.386 23.997" className="amex-icon">
    <g id="Group_6427" data-name="Group 6427" transform="translate(-185 -93)">
        <g id="Group_6426" data-name="Group 6426">
            <g id="amex" transform="translate(185 51.975)">
                <path id="Path_21795" d="M2.087 41.025H31.3a2.088 2.088 0 0 1 2.087 2.088v19.822a2.087 2.087 0 0 1-2.087 2.087H2.087A2.087 2.087 0 0 1 0 62.936V43.113a2.088 2.088 0 0 1 2.087-2.088z" className="cls-1" data-name="Path 21795"/>
                <path id="Path_21796" d="M21.532 113.966l-3.3 7.3h3.947l.489-1.163h1.122l.489 1.163h4.344v-.888l.387.888h2.247l.387-.907v.907h9.036l1.1-1.133 1.029 1.133 4.641.009-3.307-3.637 3.307-3.668h-4.571l-1.069 1.112-1-1.112h-9.826l-.844 1.883-.864-1.883h-3.938v.858l-.438-.858zM22.3 115h1.924l2.187 4.947V115h2.108l1.689 3.547L31.759 115h2.1v5.235h-1.278l-.01-4.1-1.86 4.1h-1.143l-1.871-4.1v4.1h-2.624l-.5-1.174h-2.686l-.5 1.173h-1.403zm12.728 0h5.188l1.587 1.714L43.437 115h1.587l-2.411 2.631 2.411 2.6h-1.659l-1.587-1.731-1.646 1.734h-5.108V115zm-11.792.886l-.886 2.091h1.771zm13.074.2v.956h2.83v1.065h-2.83v1.043h3.17l1.475-1.537-1.413-1.528h-3.237z" className="cls-2" data-name="Path 21796" transform="translate(-16.148 -64.595)"/>
            </g>
        </g>
    </g>
</svg>
