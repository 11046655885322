import * as React from 'react'
import './bulk-unsubscribe.css'

export const BulkUnsubscribe = (props: any) =>
<svg xmlns="http://www.w3.org/2000/svg" id="prefix__Component_27_1" width={props.svgwidth} height={props.svgheight} className="panel-icon" viewBox="0 0 29.813 23.467">
    <g id="prefix__Group_6655" data-name="Group 6655" transform="translate(-39.849 -301)">
        <g id="prefix__Group_6325" data-name="Group 6325" transform="translate(-615.258 132.127)">
            <path id="prefix__Path_21266" d="M663.476 176.976a3.861 3.861 0 0 1 3.27-6.967 3.844 3.844 0 0 0-1.966-.554 3.872 3.872 0 0 0-1.3 7.521z" className="bulk-unsub-1" data-name="Path 21266" transform="translate(5.233 .526)"/>
            <path id="prefix__Path_21267" d="M659.7 180.771a7.706 7.706 0 0 1 10.5-4.808 7.743 7.743 0 0 0-11.158 4.5 11.069 11.069 0 0 0 3.512 2.132 11.039 11.039 0 0 1-2.854-1.824z" className="bulk-unsub-1" data-name="Path 21267" transform="translate(3.554 5.527)"/>
            <path id="prefix__Path_21268" d="M657.668 173.676a1.371 1.371 0 0 1-.47-1.024 1.387 1.387 0 0 1 1.384-1.386 1.293 1.293 0 0 1 .255.051 1.371 1.371 0 1 0-1.169 2.358z" className="bulk-unsub-1" data-name="Path 21268" transform="translate(1.29 1.88)"/>
            <path id="prefix__Path_21269" d="M669.017 173.676a1.371 1.371 0 0 1-.47-1.024 1.387 1.387 0 0 1 1.386-1.386 1.244 1.244 0 0 1 .253.051 1.371 1.371 0 1 0-1.169 2.358z" className="bulk-unsub-1" data-name="Path 21269" transform="translate(11.546 1.88)"/>
            <path id="prefix__Path_21270" d="M656.349 175.918a3.23 3.23 0 0 1 3-2 3.315 3.315 0 0 1 .956.141 3.21 3.21 0 0 0-4.616 1.544 4.625 4.625 0 0 0 1.211.67 4.437 4.437 0 0 1-.551-.355z" className="bulk-unsub-1" data-name="Path 21270" transform="translate(.524 4.279)"/>
            <path id="prefix__Path_21271" d="M667.7 175.918a3.226 3.226 0 0 1 3-2 3.3 3.3 0 0 1 .954.141 3.208 3.208 0 0 0-4.614 1.544 4.586 4.586 0 0 0 1.209.67 4.29 4.29 0 0 1-.549-.355z" className="bulk-unsub-1" data-name="Path 21271" transform="translate(10.78 4.279)"/>
            <path id="prefix__Path_21272" d="M656.883 172.5a.8.8 0 0 0 .721 1.075 1.389 1.389 0 0 0 1.388-1.388 1.367 1.367 0 0 0-.47-1.022 1.294 1.294 0 0 0-.255-.051 1.387 1.387 0 0 0-1.384 1.386z" className="bulk-unsub-2" data-name="Path 21272" transform="translate(1.605 2.028)"/>
            <path id="prefix__Path_21273" d="M656.035 175.77a4.437 4.437 0 0 0 .548.358 4.96 4.96 0 0 0 4.789-.67 3.22 3.22 0 0 0-1.384-1.544 3.315 3.315 0 0 0-.956-.141 3.23 3.23 0 0 0-2.997 1.997z" className="bulk-unsub-2" data-name="Path 21273" transform="translate(.839 4.427)"/>
            <path id="prefix__Path_21274" d="M658.371 175.131a2.338 2.338 0 1 0-2.336-2.339 2.343 2.343 0 0 0 2.336 2.339zm0-3.725a1.372 1.372 0 1 1-1.384 1.386 1.375 1.375 0 0 1 1.384-1.386z" className="bulk-unsub-3" data-name="Path 21274" transform="translate(.839 1.428)"/>
            <path id="prefix__Path_21275" d="M663.315 176.358l-.089-.289a4.2 4.2 0 0 0-8.029 0l-.089.289.227.2a5.794 5.794 0 0 0 7.755 0zm-4.106-2.3a3.356 3.356 0 0 1 3 2 4.96 4.96 0 0 1-4.789.67 4.624 4.624 0 0 1-1.211-.67 3.23 3.23 0 0 1 3-1.997z" className="bulk-unsub-3" data-name="Path 21275" transform="translate(0 3.827)"/>
            <path id="prefix__Path_21276" d="M668.233 172.5a1.371 1.371 0 0 0 .47 1.024 1.421 1.421 0 0 0 .253.051 1.39 1.39 0 0 0 1.388-1.388 1.369 1.369 0 0 0-.472-1.022 1.244 1.244 0 0 0-.253-.051 1.387 1.387 0 0 0-1.386 1.386z" className="bulk-unsub-4" data-name="Path 21276" transform="translate(11.859 2.028)"/>
            <path id="prefix__Path_21277" d="M667.386 175.77a4.292 4.292 0 0 0 .546.358 4.961 4.961 0 0 0 4.789-.67 3.2 3.2 0 0 0-1.384-1.544 3.3 3.3 0 0 0-.954-.141 3.226 3.226 0 0 0-2.997 1.997z" className="bulk-unsub-4" data-name="Path 21277" transform="translate(11.094 4.427)"/>
            <path id="prefix__Path_21278" d="M669.721 175.131a2.338 2.338 0 1 0-2.336-2.339 2.342 2.342 0 0 0 2.336 2.339zm0-3.725a1.423 1.423 0 0 1 1.388 1.386 1.39 1.39 0 0 1-1.388 1.388 1.423 1.423 0 0 1-.253-.051 1.367 1.367 0 0 1-1.131-1.336 1.387 1.387 0 0 1 1.384-1.387z" className="bulk-unsub-3" data-name="Path 21278" transform="translate(11.094 1.428)"/>
            <path id="prefix__Path_21279" d="M674.574 176.069a4.2 4.2 0 0 0-8.027 0l-.089.289.227.2a5.792 5.792 0 0 0 7.753 0l.227-.2zm-4.015-2.008a3.357 3.357 0 0 1 3 2 4.961 4.961 0 0 1-4.789.67 4.585 4.585 0 0 1-1.209-.67 3.23 3.23 0 0 1 2.999-2z" className="bulk-unsub-3" data-name="Path 21279" transform="translate(10.256 3.827)"/>
            <path id="prefix__Path_21280" d="M661.247 173.5a3.868 3.868 0 0 0 1.915 3.327 3.812 3.812 0 0 0 1.3.242 3.745 3.745 0 1 0-3.219-3.569z" className="bulk-unsub-4" data-name="Path 21280" transform="translate(5.548 .674)"/>
            <path id="prefix__Path_21281" d="M659.389 180.286a10.58 10.58 0 0 0 2.724 1.708 10.738 10.738 0 0 0 10.826-2 7.3 7.3 0 0 0-3.516-4.219 7.5 7.5 0 0 0-2.943-.62 7.408 7.408 0 0 0-7.091 5.131z" className="bulk-unsub-4" data-name="Path 21281" transform="translate(4.492 6.168)"/>
            <path id="prefix__Path_21282" d="M665.306 178.853a4.99 4.99 0 1 0-4.989-4.991 5 5 0 0 0 4.989 4.991zm0-8.872a3.926 3.926 0 0 1 3.881 3.881 3.884 3.884 0 0 1-3.881 3.881 3.812 3.812 0 0 1-1.3-.242 3.872 3.872 0 0 1 1.3-7.521z" className="bulk-unsub-3" data-name="Path 21282" transform="translate(4.707)"/>
            <path id="prefix__Path_21283" d="M667.051 174.407a8.879 8.879 0 0 0-8.581 6.613l-.084.316.238.225a12.2 12.2 0 0 0 16.856 0l.236-.225-.084-.316a8.875 8.875 0 0 0-8.581-6.613zm0 1.11a7.813 7.813 0 0 1 7.418 5.469 11.1 11.1 0 0 1-14.836 0 7.768 7.768 0 0 1 7.418-5.469z" className="bulk-unsub-3" data-name="Path 21283" transform="translate(2.963 5)"/>
        </g>
    </g>
    <g id="prefix__Group_6328" data-name="Group 6328" transform="translate(-678.975 -221.197)">
        <path id="prefix__Path_21291" d="M701.835 233.517a4.424 4.424 0 0 0-2.443.736l6.249 6.25a4.425 4.425 0 0 0 .736-2.443 4.556 4.556 0 0 0-4.542-4.543z" className="bulk-unsub-5" data-name="Path 21291" transform="translate(.29 .139)"/>
        <path id="prefix__Path_21292" d="M701.987 242.449a4.426 4.426 0 0 0 2.444-.736l-6.249-6.25a4.418 4.418 0 0 0-.737 2.444 4.546 4.546 0 0 0 4.542 4.542z" className="bulk-unsub-5" data-name="Path 21292" transform="translate(.139 .29)"/>
        <path id="prefix__Path_21293" d="M702.087 232.231a5.9 5.9 0 0 0-4.187 1.733 5.926 5.926 0 0 0 8.371 8.39l.014-.01.01-.014a5.924 5.924 0 0 0-4.2-10.1zm0 11.008a5.054 5.054 0 0 1-3.863-8.345l7.127 7.128a5.037 5.037 0 0 1-3.264 1.217zm3.864-1.816l-7.127-7.128a5.055 5.055 0 0 1 7.127 7.128z" className="bulk-unsub-4" data-name="Path 21293" transform="translate(.039 .039)"/>
        <path id="prefix__Path_21294" d="M702.125 231.731a6.422 6.422 0 0 0-4.507 1.831l-.062.06-.059.06a6.466 6.466 0 0 0 4.634 10.976 6.514 6.514 0 0 0 1.2-.116c.021 0 .041-.005.06-.01a6.332 6.332 0 0 0 1.1-.327l.1-.037a6.515 6.515 0 0 0 .995-.522l.1-.06a6.433 6.433 0 0 0 .9-.724c.014-.013.03-.022.043-.034l.009-.005.065-.062.054-.055.005-.011c.013-.012.022-.028.033-.041a6.462 6.462 0 0 0 .719-.893c.027-.04.048-.082.074-.123a6.507 6.507 0 0 0 .511-.971c.015-.039.027-.079.042-.117a6.378 6.378 0 0 0 .326-1.088c0-.019.005-.039.009-.059a6.455 6.455 0 0 0 .117-1.208 6.474 6.474 0 0 0-6.468-6.464zm4.2 10.644l-.01.014-.014.01a5.926 5.926 0 0 1-8.371-8.39 5.925 5.925 0 0 1 8.388 8.372z" className="bulk-unsub-3" data-name="Path 21294"/>
        <path id="prefix__Path_21295" d="M701.894 233.017a5.037 5.037 0 0 0-3.263 1.217l7.127 7.128a5.055 5.055 0 0 0-3.864-8.345zm3.805 7.525l-6.249-6.25a4.424 4.424 0 0 1 2.443-.736 4.556 4.556 0 0 1 4.542 4.543 4.425 4.425 0 0 1-.735 2.443z" className="bulk-unsub-3" data-name="Path 21295" transform="translate(.231 .1)"/>
        <path id="prefix__Path_21296" d="M696.945 237.966a5.055 5.055 0 0 0 8.345 3.864l-7.127-7.128a5.033 5.033 0 0 0-1.218 3.264zm7.525 3.805a4.426 4.426 0 0 1-2.444.736 4.546 4.546 0 0 1-4.542-4.542 4.418 4.418 0 0 1 .737-2.444z" className="bulk-unsub-3" data-name="Path 21296" transform="translate(.1 .231)"/>
    </g>
</svg>
