import * as React from 'react'

export const Article = (props: any) =>
<svg width={props.svgwidth} height={props.svgheight} viewBox="0 0 86 86" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect width={props.svgwidth * 1.72} height={props.svgheight * 1.72} fill="#F9FBFC"/>
  <path d="M18 38C18 52.7 18.2 54.2 20 56C21.1 57.1 23.1 58 24.4 58C26.3 58 26.9 58.6 27.2 60.7L27.5 63.5H47.5H67.5V46.2C67.5 29.1 67.5 28.8 65.1 26.9C63.5 25.6 61.2 25 57.4 25C53.3 25 52 24.6 52 23.5C52 22.2 49.4 22 35 22H18V38ZM47.8 39.9L48.1 54.1L35.3 53.8L22.5 53.5L22.2 40.6C22.1 33.4 22.1 27.1 22.4 26.4C22.7 25.6 26.5 25.3 35.2 25.5L47.5 25.7L47.8 39.9ZM55.8 41.2C55.5 51.3 55.2 53.5 54 53.5C52.8 53.5 52.4 51.5 52.2 42.6C51.9 30.6 52.2 29 54.5 29C55.8 29 56 30.7 55.8 41.2ZM63.8 45.4L63.5 59.5L47.3 59.8C36.6 60 31 59.7 31 59C31 58.4 35.6 58 43.5 58C60.2 58 60 58.2 60 42.2C60 35.7 60.3 30 60.7 29.7C61 29.3 61.9 29.5 62.7 30.2C63.7 31 64 34.4 63.8 45.4Z" fill="#BDC4C9"/>
  <path d="M24 31.5V35H31H38V31.5V28H31H24V31.5Z" fill="#BDC4C9"/>
  <path d="M40 31.5C40 34.7 40.2 35 43 35C45.8 35 46 34.7 46 31.5C46 28.3 45.8 28 43 28C40.2 28 40 28.3 40 31.5Z" fill="#BDC4C9"/>
  <path d="M25.2 36.5C25.9 37.2 45 37.3 45.8 36.6C46.1 36.3 41.5 36 35.5 36C29.5 36 24.9 36.2 25.2 36.5Z"
        fill="#BDC4C9"/>
  <path d="M29.2 39.7C32.4 39.9 37.6 39.9 40.7 39.7C43.9 39.5 41.3 39.4 35 39.4C28.7 39.4 26.1 39.5 29.2 39.7Z" fill="#BDC4C9"/>
  <path d="M25 42C25 42.6 29.2 43 35.5 43C41.8 43 46 42.6 46 42C46 41.4 41.8 41 35.5 41C29.2 41 25 41.4 25 42Z" fill="#BDC4C9"/>
  <path d="M29.2 44.7C32.4 44.9 37.6 44.9 40.7 44.7C43.9 44.5 41.3 44.4 35 44.4C28.7 44.4 26.1 44.5 29.2 44.7Z" fill="#BDC4C9"/>
  <path d="M24.5 47C24.8 47.6 29.5 48 35 48C40.5 48 45.2 47.6 45.5 47C45.9 46.4 42.2 46 35 46C27.8 46 24.1 46.4 24.5 47Z" fill="#BDC4C9"/>
  <path d="M29.2 49.7C32.4 49.9 37.6 49.9 40.7 49.7C43.9 49.5 41.3 49.4 35 49.4C28.7 49.4 26.1 49.5 29.2 49.7Z" fill="#BDC4C9"/>
  <path d="M29.2 51.7C32.4 51.9 37.6 51.9 40.7 51.7C43.9 51.5 41.3 51.4 35 51.4C28.7 51.4 26.1 51.5 29.2 51.7Z" fill="#BDC4C9"/>
</svg>
